import type { MouseEvent } from "react";
import { useState } from "react";

import type { TypographyProps } from "@mui/material";
import { Skeleton, Tooltip, Typography } from "@mui/material";

export type TooltipLabelProps = TypographyProps & {
  labelContent: string | number;
  tooltipContent?: string | number;
  loading?: boolean;
  alwaysShow?: boolean;
  styles?: React.CSSProperties;
  testId?: string;
};

export const TooltipLabel = (props: TooltipLabelProps) => {
  const {
    loading,
    labelContent,
    variant,
    tooltipContent,
    alwaysShow,
    sx,
    styles,
    testId,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const shouldShowTooltip = (event: MouseEvent) => {
    const ref: any = event.target;
    const compare = ref.scrollWidth > ref.clientWidth;
    setShowTooltip(compare);
  };

  return (
    <Tooltip
      title={tooltipContent ? tooltipContent : labelContent}
      disableHoverListener={alwaysShow ? false : !showTooltip}
      style={{
        lineHeight: "normal",
        width: "inherit",
        whiteSpace: "nowrap",
        textAlign: "inherit",
        ...styles,
      }}
    >
      {loading ? (
        <Skeleton width={80} />
      ) : (
        <Typography
          onMouseOver={shouldShowTooltip}
          variant={variant ?? "toolTipLabel"}
          sx={sx}
          data-test={testId ?? ""}
        >
          {labelContent}
        </Typography>
      )}
    </Tooltip>
  );
};
