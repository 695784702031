import { Typography, Stack, ListItemButton, ListItemText } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { useAllAssessments } from "@/hooks/useAssessment";
import { useGetRiskRatings } from "@/hooks/useRiskRatings";

const getSelectedTab = (pathname: string) => {
  if (pathname.includes("assets")) return "assets";
  if (pathname.includes("risk-ratings")) return "risk-ratings";
  if (pathname.includes("strategies")) return "strategies";
  if (pathname.includes("risk-metrics")) return "risk-metrics";
  if (pathname.includes("files")) return "files";
  return "overview";
};

interface Props {
  isShown: boolean;
}

export const GroupTabs = (props: Props) => {
  const { isShown } = props;

  const router = useRouter();
  const { query, pathname } = router;
  const { groupId } = query;
  const selectedTab = getSelectedTab(pathname);
  const { t } = useTranslation();

  const { data: assessments } = useAllAssessments(groupId as string, true);
  const { data: riskRatings } = useGetRiskRatings(groupId as string, true);

  const noRatings = () => !riskRatings || riskRatings.length === 0;
  const noAssessments = () => !assessments || assessments.length === 0;

  const tabs = [
    "overview",
    "assets",
    noRatings() ? "" : "risk-ratings",
    "strategies",
    noAssessments() ? "" : "risk-metrics",
    "files",
  ].filter((tab) => !!tab);

  return (
    <Stack my={1}>
      {tabs.map((item) => (
        <Link
          key={item}
          href={`/groups/${groupId}/${item === "overview" ? "" : item}`}
          passHref
          legacyBehavior
        >
          <ListItemButton
            aria-selected={selectedTab === item}
            component="a"
            data-test={isShown ? `group-tab-${item}` : ""}
            disableRipple
            sx={{
              color: selectedTab === item ? "primary" : "rgba(0, 0, 0, 0.4)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <ListItemText>
              <Typography fontWeight={600} variant="body1" pl={3} pr={1}>
                {t(item)}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </Link>
      ))}
    </Stack>
  );
};
