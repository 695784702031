import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import { useRouter } from "next/router";

import { organization } from "@/config";
import {
  useUpdateMyRoleToGuest,
  useUpdateOrganizationForUser,
} from "@/hooks/useAllOrganizations";
import { clearCache, useUserInfo } from "@/hooks/useUserInfo";

import type { AlertParameters } from "./common/alert/Alert";
import { Alert } from "./common/alert/Alert";
import { getErrorDetails } from "./common/alert/alert-utils";
import { Welcome } from "./welcome/Welcome";

export const LandingPage = () => {
  const { data } = useUserInfo();
  const router = useRouter();
  const handleUpdateUserOrganization = useUpdateOrganizationForUser();
  const handleUpdateMyRoleToGuest = useUpdateMyRoleToGuest();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertParameters>({ content: {} });

  const onTryDemoClick = async () => {
    if (!data || !data.user) return;

    setLoading(true);

    const username = data.user.cognito_username;
    const demoId = organization.demoId;

    try {
      await handleUpdateUserOrganization(demoId, username);
      await handleUpdateMyRoleToGuest();
      await clearCache();

      router.reload();
    } catch (error: any) {
      console.error(
        `Adding user '${username}' to the demo organization failed:`,
        error
      );
      setLoading(false);

      setAlert({
        content: {
          showAlert: true,
          body: `There was an error while adding user '${username}' to the demo organization.`,
          details: getErrorDetails(error),
        },
      });
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} pb={2}>
            <Card variant="outlined">
              <Typography padding={2} variant="h3">
                Welcome to Iris
              </Typography>
              <Typography pl={2} variant="body1">
                It seems you are not yet part of an organization.
              </Typography>
              <Typography pl={2} variant="body1">
                Click the button below to try out our demo.
              </Typography>

              <Box display="flex" p={2}>
                <LoadingButton
                  variant="outlined"
                  onClick={onTryDemoClick}
                  loading={loading}
                >
                  Try Demo
                </LoadingButton>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} pb={2}>
            <Welcome />
          </Grid>
        </Grid>
      </Container>

      <Alert content={alert.content} properties={alert.properties} />
    </>
  );
};
