import useSWR, { mutate } from "swr";

import type {
  AssessmentTypeEnum,
  RiskRatingConsequenceType,
  RCPScenarioEnum,
  ReferenceIds,
  RiskRatingBatchRequest,
  RiskRatingHazardsEnum,
  RiskRatingRequest,
  RiskRatingResponse,
  StatusEnum,
  UpdateRiskRatingRequest,
} from "@/apis/services/HazardService";
import { ReferenceTypeEnum } from "@/apis/services/HazardService";
import type { QueryValues } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";

import { useServices } from "./useServices";

export const useGetRiskRatings = (
  id: string,
  isGroup?: boolean
): { data: RiskRatingResponse[]; isLoading: boolean; isError: boolean } => {
  const { hazard } = useServices();
  const { data, error } = useSWR(
    id ? ["useGetRiskRatings", id] : null,
    async () => {
      const ratings = await hazard.riskRatings.getRiskRatings({
        ref_id: id,
        ref_type: isGroup ? ReferenceTypeEnum.GROUP : ReferenceTypeEnum.ASSET,
      });
      return ratings.data;
    }
  );

  return {
    // in case of an error just send back an empty array as a result
    // this can happen if the asset has no risk ratings added yet (or they are all deleted)
    data:
      !data || error
        ? []
        : // sort the results on the newest item first
          data?.sort(
            (a, b) =>
              Date.parse(b.created_at || "January 1, 1970") -
              Date.parse(a.created_at || "0")
          ),
    isLoading: !error && !data,
    isError: error,
  };
};

export const useGetRiskRatingVersions = (riskRatingId: string) => {
  const { hazard } = useServices();
  const { data, error, isLoading } = useSWR(
    () => (riskRatingId ? ["useGetRiskRatingVersions", riskRatingId] : null),
    async () => {
      const ratings = await hazard.riskRatings.getRiskRatingVersions(
        riskRatingId
      );
      return ratings.data;
    }
  );

  return {
    data,
    isLoading,
    isError: error && error.status !== 404,
  };
};

export const useCreateRiskRatings = () => {
  const { hazard } = useServices();

  const handleCreateRiskRatings = async (data: RiskRatingRequest) => {
    const {
      data: { id },
    } = await hazard.riskRatings.createRiskRating(data);
    mutate(["useGetRiskRatings", data.ref_id]);
    mutate(["useGetRiskRatingVersions", id]);
    mutate(["useHighTideRiskRating", data.ref_id]);
  };
  return handleCreateRiskRatings;
};

export const useUpdateRiskRatings = () => {
  const { hazard } = useServices();

  const handleUpdateRiskRatings = async (
    ratingId: string,
    data: UpdateRiskRatingRequest,
    query: {
      version?: number | null;
    },
    refId: string
  ) => {
    await hazard.riskRatings.updateRiskRating(ratingId, data, {
      ...query,
      version: query.version ?? undefined,
    });
    mutate(["useGetRiskRatings", refId]);
    mutate(["useGetRiskRatingVersions", ratingId]);
    mutate(["useHighTideRiskRating", refId]);
  };
  return handleUpdateRiskRatings;
};

export const useUpdateRiskRatingStatus = () => {
  const { hazard } = useServices();

  const handleUpdateRiskRatingsStatus = async (
    id: string,
    query: {
      status: StatusEnum;
      version: number;
    },
    refId: string
  ) => {
    await hazard.riskRatings.updateRiskRatingStatus(id, query);
    mutate(["useGetRiskRatings", refId]);
    mutate(["useGetRiskRatingVersions", id]);
    mutate(["useHighTideRiskRating", refId]);
  };
  return handleUpdateRiskRatingsStatus;
};

export const useDeleteRiskRating = () => {
  const { hazard } = useServices();

  const handleDeleteRiskRatings = async (
    id: string,
    query: {
      version?: number;
    },
    refId: string
  ) => {
    await hazard.riskRatings.deleteRiskRating(id, query);
    mutate(["useGetRiskRatings", refId]);
    mutate(["useGetRiskRatingVersions", id]);
    mutate(["useHighTideRiskRating", refId]);
  };
  return handleDeleteRiskRatings;
};

export type GetTopHazardQuery = {
  ref_type: ReferenceTypeEnum;
  assessment_type?: AssessmentTypeEnum;
  rcp_scenario?: RCPScenarioEnum;
  time_horizon?: number;
};

export const useGetTopHazards = (
  query: GetTopHazardQuery,
  request: ReferenceIds
) => {
  const { hazard } = useServices();
  const { ref_type, assessment_type, rcp_scenario, time_horizon } = query;
  const key = `${ref_type}${assessment_type}${rcp_scenario}${time_horizon}`;
  const ids = request.ref_ids ?? [];

  const { data, error, isLoading } = useSWR(
    ids.length === 0
      ? null
      : ["useGetTopHazards", `${key}${ids.sort().join(",")}`],
    async () => {
      const topHazards = await hazard.riskRatings.getTopHazards(query, request);
      return topHazards.data;
    }
  );
  return {
    data,
    isLoading: (!error && !data) || isLoading,
    isError: error,
  };
};

const generateSearchRiskRatingKey = (
  ids: string[],
  hazards?: RiskRatingHazardsEnum[] | null,
  consequences?: RiskRatingConsequenceType[] | null,
  query?: QueryValues
) => {
  return [
    "useSearchRiskRatingFunction",
    ids.join(","),
    JSON.stringify(hazards),
    JSON.stringify(consequences),
    JSON.stringify(query),
  ];
};

export const useSearchRiskRatingFunction = (
  { hazards, consequences, ref_ids }: RiskRatingBatchRequest,
  query?: QueryValues
) => {
  const { hazard } = useServices();
  const ids = (ref_ids ?? []).sort();
  const key = generateSearchRiskRatingKey(ids, hazards, consequences, query);

  const { data, error, isLoading } = useSWR(
    ids.length > 0 ? key : null,
    async () => {
      const assets = await hazard.riskRatings.searchRiskRating(
        {
          ref_type: ReferenceTypeEnum.ASSET,
          assessment_type: query?.assessmentType,
          rcp_scenario: query?.climateScenario as RCPScenarioEnum,
          time_horizon: query?.timeHorizon as number,
        },
        {
          ref_ids: ids,
          hazards,
          consequences,
        }
      );
      return assets.data;
    }
  );
  return {
    data,
    isLoading: isLoading,
    isError: error,
  };
};

export const useGenerateSummary = () => {
  const { hazard } = useServices();

  const handleGenerateSummary = async (id: string) => {
    const response = await hazard.riskRatings.generateRiskRatingSummary(id);
    mutate(["useGetRiskRatingVersions", id]);
    return response.data;
  };

  return handleGenerateSummary;
};
