import { RiskRatingEnum } from "@/apis/services/HazardService";

import { getTime } from "./date-utils";
import { capitalizeFirstLetter } from "./string-utils";

interface SortOptions {
  value: "name" | "assets";
  ascending: boolean;
}

export const formatArrayResults = (array?: string[]): string => {
  if (!array || array.length === 0) return "-";
  if (array.every((item) => !item)) return "-";
  return capitalizeArrayElements(array.filter((str) => !!str).slice(0, 3)).join(
    ", "
  );
};

const capitalizeArrayElements = (array: string[]): string[] => {
  return array.map((item) => capitalizeFirstLetter(item));
};

export const convertToArrayOfNumberFromComma = (
  inputString: string | undefined,
  parseNumber: Function
) => {
  const numberArray: number[] = [];
  if (inputString) {
    const arrayOfStrings: string[] = inputString.split(",");
    arrayOfStrings.forEach((element: string) => {
      numberArray.push(parseNumber(element));
    });
  }
  return numberArray;
};

export const onlyUnique = (value: any, index: number, self: Array<any>) => {
  return self.indexOf(value) === index;
};

export const getArrayOfNumbersToUpperLimit = () => {
  const arr: string[] = [];
  const limit = Object.keys(RiskRatingEnum).length;

  for (let i = 1; i <= limit; i++) arr.push(`${i}`);
  return arr;
};

export const groupArrayByProperty = <Type, Key extends keyof Type>(
  array: Array<Type> | undefined,
  groupBy: Key
): { [key: string]: Type[] } => {
  if (array && array.length > 0) {
    const groupedArray = array.reduce((r, a) => {
      r[a[groupBy]] = r[a[groupBy]] || [];
      r[a[groupBy]].push(a);
      return r;
    }, Object.create(null));
    return groupedArray;
  }
  return {};
};

export const sortObjectWithObject = <Type extends object>(
  mainObject: Type,
  sortObject: any
) => {
  const sorted = Object.entries(mainObject).sort(
    (a, b) =>
      Object.keys(sortObject).indexOf(a[0]) -
      Object.keys(sortObject).indexOf(b[0])
  );
  return Object.fromEntries(sorted);
};

export const equalsArray = <T>(a: T[] | undefined, b: T[] | undefined) =>
  JSON.stringify(a) === JSON.stringify(b);

export const sortArrayOfObjectsByProperty = <T, U extends keyof T>(
  array: T[],
  orderBy: U | U[],
  order: string = "des",
  isDate?: boolean
) => {
  let itemAValue: number;
  let itemBValue: number;

  if (order === "des") {
    return array.sort((a, b) => {
      itemAValue = isDate
        ? getTime(
            (a[(orderBy as U[])[0]] ||
              a[(orderBy as U[])[1]]) as unknown as string
          )
        : (a[orderBy as U] as unknown as number);
      itemBValue = isDate
        ? getTime(
            (b[(orderBy as U[])[0]] ||
              b[(orderBy as U[])[1]]) as unknown as string
          )
        : (b[orderBy as U] as unknown as number);
      return itemAValue - itemBValue;
    });
  } else if (order === "inc") {
    return array.sort((a, b) => {
      itemAValue = isDate
        ? getTime(
            (a[(orderBy as U[])[0]] ||
              a[(orderBy as U[])[1]]) as unknown as string
          )
        : (a[orderBy as U] as unknown as number);
      itemBValue = isDate
        ? getTime(
            (b[(orderBy as U[])[0]] ||
              b[(orderBy as U[])[1]]) as unknown as string
          )
        : (b[orderBy as U] as unknown as number);
      return itemBValue - itemAValue;
    });
  }
  return array;
};

export const getFirstValue = (array?: any[] | null): any => {
  if (!array || array.length === 0) return "";
  return array[0];
};

export const updateArray = <T>(
  arr?: T[] | null,
  newLength: number = 0
): T[] => {
  if (!arr) return [];

  const oldLength = arr.length;
  if (newLength < oldLength) {
    return [...arr].slice(0, newLength);
  }

  return [...arr].concat(Array(newLength - oldLength).fill(0));
};

export const sortArray = <T>(arr: T[], options: SortOptions): T[] => {
  const value = options.value;
  const ascending = options.ascending;
  // since .sort happens in place we need to create a clone of the original
  const clone = [...arr];

  if (ascending) {
    if (value === "name")
      return clone.sort((a: any, b: any) => a.name.localeCompare(b.name));
    if (value === "assets")
      return clone.sort(
        (a: any, b: any) => a.metadata.total_assets - b.metadata.total_assets
      );
  }

  if (value === "name")
    return clone.sort((a: any, b: any) => b.name.localeCompare(a.name));
  if (value === "assets")
    return clone.sort(
      (a: any, b: any) => b.metadata.total_assets - a.metadata.total_assets
    );

  // nothing to sort so just return the original order
  return clone;
};

export const getSelected = <T>(available: T[], max: number): T[] => {
  return available.length <= max ? available : available.slice(0, max);
};

export const getUniqueValues = <T>(arr1?: T[], arr2?: T[]) => {
  const set1 = new Set(arr1 ?? []);
  const set2 = new Set(arr2 ?? []);
  const merged = new Set([...set1, ...set2]);
  return Array.from(merged);
};

export const intersectValues = <T>(arr1?: T[], arr2?: T[]) => {
  return arr1?.filter((val) => arr2?.includes(val)) ?? [];
};
export const formatArrayToCommaSeparatedString = <T>(arr: T[]) => {
  return arr
    .map((item, index) => {
      if (arr.length === 1) return item;
      if (index === arr.length - 1) return `and ${item}`;
      if (index === arr.length - 2) return `${item}`;
      return `${item},`;
    })
    .join(" ");
};
