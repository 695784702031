import type { CSSProperties } from "react";
import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { GroupResponse } from "@/apis/services/AssetService";
import { Alert } from "@/components/common/alert/Alert";
import { useCreateAssetGroup } from "@/hooks/useAllAssetGroups";
import { useEditGroup, useGroupTypes } from "@/hooks/useGroup";
import { getCardPopoverStyle } from "@/styles/CardPopover";

import { getErrorDetails } from "./alert/alert-utils";

interface CreateAssetGroupForm extends GroupResponse {}

interface Props {
  "data-test"?: string;
  isEditMode?: boolean;
  group?: GroupResponse;
  handleCloseMenuPopup?: () => void;
  buttonStyle?: CSSProperties;
}

export const CreateOrEditAssetGroupPopover = (props: Props) => {
  const { isEditMode, group, handleCloseMenuPopup } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>({ content: {} });

  const handleCreateAssetGroup = useCreateAssetGroup();
  const handleEditGroup = useEditGroup();
  const { t } = useTranslation();
  const { data: groupTypes } = useGroupTypes();
  const { control, handleSubmit, reset } = useForm<CreateAssetGroupForm>({
    defaultValues: {
      name: group?.name,
      description: group?.description,
      type: group?.type,
    },
  });

  const handleCloseModal = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
    setLoading(false);
    reset();
  };

  const onSubmit = async (data: CreateAssetGroupForm) => {
    setLoading(true);
    try {
      isEditMode
        ? await handleEditGroup(group!.id, data)
        : await handleCreateAssetGroup(data);
      setAlert({
        content: {
          showAlert: true,
          body: `Group is ${isEditMode ? "updated" : "created"}`,
        },
        properties: {
          severity: "success",
          duration: 3000,
        },
      });
    } catch (error) {
      console.error(
        `There is an error in ${
          isEditMode ? "handleEditGroup" : "handleCreateAssetGroup"
        }`,
        error
      );
      setAlert({
        content: {
          showAlert: true,
          body: `There was an error while ${
            isEditMode ? "updating" : "creating"
          } the group.`,
          details: getErrorDetails(error),
        },
      });
    }
    handleCloseMenuPopup && handleCloseMenuPopup();
    handleCloseModal();
    setLoading(false);
  };

  return (
    <div>
      {isEditMode ? (
        <Typography
          variant="body1"
          onClick={handleOpen}
          data-test="edit-group-btn"
        >
          Edit
        </Typography>
      ) : (
        <Button
          data-test="create-group-btn"
          onClick={handleOpen}
          color="inherit"
          style={{ ...props.buttonStyle }}
        >
          Create group
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="create-asset-group-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            sx={getCardPopoverStyle(true)}
            data-test="create-asset-group-modal"
          >
            <CardHeader
              id="modal-modal-title"
              title={isEditMode ? "Edit Group" : "Create New Group"}
              subheader={
                isEditMode
                  ? "Change the type or name of the group"
                  : "Select the type of group and give it a name"
              }
            />
            <Divider />
            <CardContent sx={{ mt: 2, width: 500 }}>
              <Stack spacing={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Group Name"
                      required
                      fullWidth
                      inputProps={{ ["data-test"]: "group-name-field" }}
                    />
                  )}
                />
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl sx={{ width: 200 }} required>
                      <InputLabel id="types">Group Type</InputLabel>
                      <Select
                        {...field}
                        data-test="group-type-field"
                        labelId="types"
                        label="Group Type"
                        inputProps={{
                          ["data-test"]: "group-type-input",
                        }}
                      >
                        {groupTypes?.map((groupType) => (
                          <MenuItem
                            key={groupType}
                            value={groupType}
                            data-test="group-type-options"
                          >
                            {t(groupType)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>
            </CardContent>
            <CardActions>
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                <LoadingButton
                  loading={loading}
                  size="medium"
                  type="submit"
                  data-test="create-group-submit"
                >
                  {isEditMode ? "Edit" : "Create"} Group
                </LoadingButton>
              </Stack>
            </CardActions>
          </Card>
        </form>
      </Modal>
      <Alert content={alert.content} properties={alert.properties} />
    </div>
  );
};
