import {
  ListItemButton,
  ListItemText,
  Stack,
  Chip,
  Collapse,
  List,
} from "@mui/material";
import Link from "next/link";

import type { GroupResponse } from "@/apis/services/AssetService";
import { TooltipLabel } from "@/components/common/label/TooltipLabel";
import { DefaultLoader } from "@/components/common/loading/DefaultLoader";
import { GroupTabs } from "@/components/group/GroupTabs";

type Props = {
  group: GroupResponse;
  selectedGroupId: string;
};

export const GroupNavigationItem = ({ group, selectedGroupId }: Props) => {
  const details = group.metadata;

  return (
    <>
      <Link href={`/groups/${group.id}`} passHref legacyBehavior>
        <ListItemButton
          component="a"
          selected={group.id === selectedGroupId}
          data-test={`group-menu-item-${group.name}`}
        >
          <ListItemText disableTypography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <TooltipLabel
                labelContent={group.name}
                testId="group-menu-item-text"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
              />
              {!details ? (
                <DefaultLoader
                  height={19}
                  sx={{ borderRadius: "16px", minWidth: "38px" }}
                />
              ) : (
                <Chip
                  sx={{
                    minWidth: "38px",
                    height: "19px",
                    backgroundColor: "#E8E8E8",
                    fontWeight: "normal",
                  }}
                  label={details.total_assets}
                  size="small"
                />
              )}
            </Stack>
          </ListItemText>
        </ListItemButton>
      </Link>
      <Collapse in={group.id === selectedGroupId} timeout="auto">
        <List component="div" disablePadding>
          <GroupTabs isShown={group.id === selectedGroupId} />
        </List>
      </Collapse>
    </>
  );
};
