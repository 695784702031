import type { CSSProperties } from "react";

import { AddBusiness } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Link from "next/link";

type NewAssetButtonProps = {
  path: string;
  loading?: boolean;
  buttonStyle?: CSSProperties;
};

export const NewAssetButton = ({
  path,
  loading,
  buttonStyle,
}: NewAssetButtonProps) => {
  const { breakpoints } = useTheme();
  const isScreenSmallerThanMdSize = useMediaQuery(breakpoints.down("md"));

  return (
    <Link href={path} passHref style={{ textDecoration: "none" }}>
      {isScreenSmallerThanMdSize ? (
        <Tooltip title="New Asset">
          <IconButton disabled={loading} sx={{ border: "1px solid #3d3d3c" }}>
            <AddBusiness />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          disabled={loading}
          data-test="create-asset-btn"
          disableElevation
          sx={{ ...buttonStyle }}
        >
          New asset
        </Button>
      )}
    </Link>
  );
};
