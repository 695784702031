import { createContext, useContext, useState } from "react";

import { Grid } from "@mui/material";
import Head from "next/head";

import { OrganizationSettingsContext } from "@/components/context/organizationSettingsContext";
import { LandingPage } from "@/components/LandingPage";
import { OverviewNavigation } from "@/components/navigation/overview/OverviewNavigation";
import { useUserInfo } from "@/hooks/useUserInfo";

type MenuCollapsedContextProps = {
  menuCollapsed: boolean;
  setMenuCollapsed: any;
};
export const MenuCollapsedContext = createContext(
  {} as MenuCollapsedContextProps
);

type Props = {
  children: any;
};
export const PortfolioLayout = ({ children }: Props) => {
  const userInfo = useUserInfo();

  const { isLoadingSettings } = useContext(OrganizationSettingsContext);

  const getOrganization = () => userInfo?.data?.user?.organization;
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  if (!getOrganization()) {
    return (
      <>
        <Head>
          <title>Portfolio</title>
        </Head>
        <LandingPage />;
      </>
    );
  }

  if (isLoadingSettings) return <></>;

  return (
    <Grid container>
      <MenuCollapsedContext.Provider
        value={{ menuCollapsed, setMenuCollapsed }}
      >
        <Grid
          item
          xs={menuCollapsed ? 0 : 4}
          sm={menuCollapsed ? 0 : 3}
          md={menuCollapsed ? 0 : 3}
          lg={menuCollapsed ? 0 : 2}
          xl={menuCollapsed ? 0 : 2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            maxWidth: "280px",
          }}
        >
          <OverviewNavigation />
        </Grid>

        <Grid
          item
          style={{ paddingTop: menuCollapsed ? 0 : "" }}
          xs={menuCollapsed ? 12 : 8}
          sm={menuCollapsed ? 12 : 9}
          md={menuCollapsed ? 12 : 9}
          lg={menuCollapsed ? 12 : 10}
          xl={menuCollapsed ? 12 : 10}
        >
          {children}
        </Grid>
      </MenuCollapsedContext.Provider>
    </Grid>
  );
};
