import { useContext, useEffect, useState } from "react";

import {
  ArrowDownward,
  ArrowUpward,
  ExpandCircleDown,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Divider,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Container,
  Skeleton,
} from "@mui/material";
import Link from "next/link";

import type { GroupResponse } from "@/apis/services/AssetService";
import { CreateOrEditAssetGroupPopover } from "@/components/common/CreateOrEditAssetGroupPopover";
import { NewAssetButton } from "@/components/common/NewAssetButton";
import { MenuCollapsedContext } from "@/components/layouts/PortfolioLayout";
import { useAllAssetGroups } from "@/hooks/useAllAssetGroups";
import { usePortfolioViewer } from "@/hooks/useAllOrganizations";
import { useUserInfo } from "@/hooks/useUserInfo";
import { sortArray } from "@/utils/array-utils";
import { isClient } from "@/utils/user-utils";

import { GroupNavigationItem } from "./GroupNavigationItem";

export type Props = {
  groupId?: string | null;
};

export const OverviewNavigation = ({ groupId }: Props) => {
  const { data } = useUserInfo();

  const client = isClient(data?.user?.roles);
  const { menuCollapsed, setMenuCollapsed } = useContext(MenuCollapsedContext);

  const { data: groupData, isLoading } = useAllAssetGroups(true, true);
  const { data: viewer } = usePortfolioViewer(data?.user?.organization?.id);

  const getSortedGroups = (data: GroupResponse[]) => {
    const options = { value: sortValue, ascending: ascending };
    const sorted = sortArray(data, options);
    return sorted;
  };

  const [sortValue, setSortValue] = useState(() => {
    const storedState = localStorage.getItem("iris-nav-sortValue");
    return storedState ? JSON.parse(storedState) : "name";
  });

  const [ascending, setAscending] = useState(() => {
    const storedState = localStorage.getItem("iris-nav-ascending");
    return storedState ? JSON.parse(storedState) : true;
  });

  const [groups, setGroups] = useState<GroupResponse[]>(
    getSortedGroups(groupData ?? [])
  );
  const [menuIsHovered, setMenuIsHovered] = useState(false);

  useEffect(() => {
    if (!groupData || groupData.length === 0) return;
    if (!data || !data.isAuthenticated) return;

    const sorted = getSortedGroups(groupData);
    setGroups(sorted);
  }, [groupData, ascending, sortValue]);

  const handleSortValue = (event: any) => {
    const val = event.target.value;
    localStorage.setItem("iris-nav-sortValue", JSON.stringify(val));
    setSortValue(val);
  };

  const handleAscending = () => {
    localStorage.setItem("iris-nav-ascending", JSON.stringify(!ascending));
    setAscending(!ascending);
  };

  const handleMenuHover = () => {
    setMenuIsHovered(!menuIsHovered);
  };

  const handleCollapseMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const showCollapseButton = (menuIsHovered && !menuCollapsed) || menuCollapsed;
  const totalAssets = viewer?.total_assets ?? "-";

  return (
    <Container
      onMouseEnter={handleMenuHover}
      onMouseLeave={handleMenuHover}
      style={{
        position: "relative",
        marginTop: "20px",
        paddingLeft: 0,
      }}
    >
      <Box
        style={{
          position: "sticky",
          top: "69px",
          width: menuCollapsed ? 0 : "100%",
          zIndex: 100,
        }}
      >
        <Stack>
          <Stack direction="row">
            {!menuCollapsed && (
              <Typography variant="h3">
                {data?.user?.organization?.name}
              </Typography>
            )}
            {showCollapseButton && (
              <IconButton
                style={{
                  marginLeft: "auto",
                  left: menuCollapsed ? -10 : 35,
                  top: 90,
                  backgroundColor: "white",
                  padding: 1,
                  transform: menuCollapsed ? "rotate(270deg)" : "rotate(90deg)",
                }}
                onClick={handleCollapseMenu}
              >
                <ExpandCircleDown style={{ fontSize: "20px" }} />
              </IconButton>
            )}
          </Stack>
          {!menuCollapsed && (
            <>
              <Stack
                direction="row"
                style={{
                  fontSize: 10,
                  alignItems: "center",
                  marginBottom: -10,
                }}
              >
                <Typography variant="subtitle2">Groups</Typography>
                <FormControl
                  variant="standard"
                  style={{
                    marginLeft: "auto",
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                >
                  <Select
                    style={{
                      fontSize: 12,
                    }}
                    label="Sort by"
                    labelId="sortby-label"
                    value={sortValue}
                    onChange={handleSortValue}
                  >
                    <MenuItem style={{ fontSize: 12 }} value="name">
                      Name
                    </MenuItem>
                    <MenuItem style={{ fontSize: 12 }} value="assets">
                      Assets
                    </MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={handleAscending}>
                  {ascending ? <ArrowUpward /> : <ArrowDownward />}
                </IconButton>
              </Stack>
              <Stack direction="row">
                <Link href="/" passHref legacyBehavior>
                  <ListItemButton component="a" selected={!groupId}>
                    <ListItemText disableTypography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body2" pr={1}>
                          All assets
                        </Typography>
                        <Chip
                          sx={{
                            minWidth: "38px",
                            height: "19px",
                            backgroundColor: "#E8E8E8",
                            fontWeight: "normal",
                          }}
                          label={totalAssets}
                          size="small"
                        />
                      </Stack>
                    </ListItemText>
                  </ListItemButton>
                </Link>
              </Stack>
              <Divider style={{ margin: "5px 0px 5px", opacity: 0.5 }} />
              <Box
                sx={{
                  overflowY: "auto",
                  height: "68vh",
                  margin: 0,
                }}
              >
                {isLoading && <Skeleton height={50} />}
                {groups.map((group) => {
                  return (
                    <GroupNavigationItem
                      key={group.id}
                      group={group}
                      selectedGroupId={groupId as string}
                    />
                  );
                })}
              </Box>

              <Divider style={{ marginTop: 20, opacity: 0.5 }} />

              {client && (
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{
                    mt: 1,
                    mb: 2,
                    pr: 1.5,
                  }}
                >
                  <NewAssetButton
                    key="new"
                    path={
                      groupId ? `/assets/new?groupId=${groupId}` : "/assets/new"
                    }
                    buttonStyle={{ fontSize: "12px" }}
                  />
                  <CreateOrEditAssetGroupPopover
                    key="new-group"
                    buttonStyle={{ fontSize: "12px" }}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
