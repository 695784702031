import useSWR, { mutate } from "swr";

import type { GroupUpdateRequest } from "@/apis/services/AssetService";
import { THIRTY_MINUTES } from "@/constants/session";

import { useServices } from "./useServices";

export const useGroup = (groupId: string) => {
  const { asset } = useServices();
  const { data, error } = useSWR(
    groupId ? [`useGroup`, groupId] : null,
    async () => {
      const group = await asset.groups.getGroup(groupId);
      return group.data;
    }
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useEditGroup = () => {
  const { asset } = useServices();
  const handleEditGroup = async (groupId: string, data: GroupUpdateRequest) => {
    await asset.groups.updateGroup(groupId, data);
    mutate(["useGroup", groupId]);
  };
  return handleEditGroup;
};

export const useDeleteGroup = () => {
  const { asset } = useServices();
  const handleDeleteGroup = async (groupId: string) => {
    await asset.groups.deleteGroup(groupId);
    mutate("useAllAssetGroups");
  };
  return handleDeleteGroup;
};

export const useGroupTypes = () => {
  const { asset } = useServices();
  const { data, error, isLoading } = useSWR(
    "groupTypes",
    async () => {
      const groupTypes = await asset.groups.getGroupTypes();
      return groupTypes.data;
    },
    { dedupingInterval: THIRTY_MINUTES }
  );
  return { data, isLoading, error };
};
