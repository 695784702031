import useSWR, { mutate } from "swr";

import type { GroupResponse } from "@/apis/services/AssetService";
import type { AddAssetGroupForm } from "@/components/common/AssignAssetGroupPopover";

import { useServices } from "./useServices";

export const useAllAssetGroups = (shouldFetch: boolean, metaData?: boolean) => {
  const { asset } = useServices();
  const { data, error } = useSWR(
    shouldFetch ? "useAllAssetGroups" : null,
    async () => {
      const assets = await asset.groups.getGroups({ metadata: metaData });
      return assets.data;
    }
  );
  return {
    data: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCreateAssetGroup = () => {
  const { asset } = useServices();
  const handleCreateAssetGroups = async (data: GroupResponse) => {
    await asset.groups.createGroup(data);
    mutate("useAllAssetGroups");
  };
  return handleCreateAssetGroups;
};

export const useAssignAssetGroup = () => {
  const { asset } = useServices();
  const handleAssignAssetsGroup = async (data: AddAssetGroupForm) => {
    await asset.groups.assignAssetToGroup(data.groupId, data.assetId);
    mutate("useAllAssetGroups");
    mutate([`assetInGroupById`, data.groupId]);
    mutate([`groupDetailsById`, data.groupId]);
  };
  return handleAssignAssetsGroup;
};

export const useRemoveAssetGroup = () => {
  const { asset } = useServices();
  const handleRemoveAssetsGroup = async (groupId: string, assetId: string) => {
    await asset.groups.removeAssetFromGroup(groupId, assetId);
    mutate("useAllAssetGroups");
  };
  return handleRemoveAssetsGroup;
};
