export const formatDate = (
  date?: string | null,
  showTime?: boolean
): string => {
  return date
    ? showTime
      ? new Intl.DateTimeFormat("en-US", {
          dateStyle: "short",
          timeStyle: "medium",
        }).format(new Date(date))
      : new Intl.DateTimeFormat("en-US").format(new Date(date))
    : "-";
};

export const getTime = (date: string): number => {
  return new Date(date).getTime();
};

export const daysToDays = (days: number): string => {
  return days.toFixed();
};

export const daysToWeeks = (days: number, digits: number = 1): string => {
  return (days / 7).toFixed(digits);
};

export const daysToMonths = (days: number, digits: number = 1): string => {
  return ((days / 365) * 12).toFixed(digits);
};

export const daysToYears = (days: number, digits: number = 1): string => {
  return (days / 365).toFixed(digits);
};

export const sortByDate = (data: Array<any>) => {
  if (!data) return [];
  return data.sort((a, b) => {
    const date1 = Date.parse(a.updated_at || a.created_at);
    const date2 = Date.parse(b.updated_at || b.created_at);
    return date1 === date2 ? 0 : date1 < date2 ? 1 : -1;
  });
};
